import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    idToken:"",
    accessToken:"",
  },
  mutations: {
    setAuth(state, payload){
      state.idToken = payload.idToken
      state.accessToken = payload.accessToken
    },
  },
  actions: {
  },
  modules: {
  }
})
