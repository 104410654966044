import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Keycloak from 'keycloak-js'

Vue.config.productionTip = false

let initOptions = {
    url:  process.env.VUE_APP_SSO_URL, realm: process.env.VUE_APP_SSO_REALM, clientId: process.env.VUE_APP_SSO_CLIENT, onLoad: 'login-required'
}

let keycloak = Keycloak(initOptions);

keycloak.init({onLoad: initOptions.onLoad}).then((auth) => {
    if (!auth) {
        window.location.reload();
    } else {
        new Vue({
            el: '#app',
            vuetify,
            router,
            store,
            render: h => h(App)
        })
    }
    let payload = {
        idToken: keycloak.idToken,
        accessToken: keycloak.token
    }

    if (keycloak.token && keycloak.idToken && keycloak.token != '' && keycloak.idToken != '') {
        store.commit("setAuth", payload);
        //console.log("User has logged in: " + keycloak.token)
    } else {
        //store.commit("logout");
    }

    //Token Refresh
    setInterval(() => {
        keycloak.updateToken(110).then((refreshed) => {
            if (refreshed) {
                //console.log('Token refreshed' + refreshed);
                // console.error(keycloak.token)
                let payload = {
                    idToken: keycloak.idToken,
                    accessToken: keycloak.token
                }
                store.commit("setAuth", payload);

            } else {
                //console.log('Token not refreshed, valid for '
                // + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
        }).catch(() => {
            //Vue.$log.error('Failed to refresh token');
        });
    }, 6000)

}).catch(() => {
    //Vue.$log.error("Authenticated Failed");
});

