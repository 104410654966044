import axios from "axios";
import store from './store';

//console.log(store);
class axiosHttp {
  constructor () {
    this.http = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        "Content-type": "application/json"
      }
    })

    this.http.interceptors.request.use(config => {
      config.headers.Token = store.state.accessToken
      return config
    })

    this.httpPreprod = axios.create({
      baseURL: process.env.VUE_APP_API_PREPROD_URL,
      headers: {
        "Content-type": "application/json"
      }
    })
    
    this.httpPreprod.interceptors.request.use(config => {
      config.headers.Token = store.state.accessToken
      return config
    })
  }
}
export default new axiosHttp();
