<template>
    <v-app>
        <v-app-bar
                app
                color="primary"
                dark
        >
            <v-toolbar-title>Page title</v-toolbar-title>

            <v-spacer></v-spacer>
            <router-link to="/traductions">
                <v-btn icon>
                    <v-icon>mdi-flag-outline </v-icon>
                </v-btn>
            </router-link>

        </v-app-bar>

        <v-main>
            <v-container fluid>
                <router-view></router-view>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
            //
        }),
    };
</script>
