<template>
    <div class="traductions">

        <v-data-table
                :headers="headers"
                :items="keysList"
                item-key="key"
                :search="search"
                :items-per-page="15"
                class="elevation-1"
        >

            <template v-slot:top>
                <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                        class="mx-5 pt-5 mb-8 search"
                        outlined
                        dense
                ></v-text-field>
            </template>

            <template v-slot:[`item.key`]="{item}">
                <v-btn icon color="red" class="mr-2" @click.stop="currentKey = item.key, dialog = true">
                    <v-icon>mdi-delete-outline </v-icon>
                </v-btn> {{item.key}}
            </template>

            <template v-slot:[`item.fr`]="{item}">
                <v-text-field
                        v-model="item.fr"
                        hide-details
                        @blur="updateKey($event,item.key,'fr')"
                ></v-text-field>
            </template>
            <template v-slot:[`item.nl`]="{item}">
                <v-text-field
                        v-model="item.nl"
                        hide-details
                        @blur="updateKey($event,item.key,'nl')"
                ></v-text-field>
            </template>
            <template v-slot:[`item.it`]="{item}">
                <v-text-field
                        v-model="item.it"
                        hide-details
                        @blur="updateKey($event,item.key,'it')"
                ></v-text-field>
            </template>
            <template v-slot:[`item.en`]="{item}">
                <v-text-field
                        v-model="item.en"
                        hide-details
                        @blur="updateKey($event,item.key,'en')"
                ></v-text-field>
            </template>
        </v-data-table>


        <v-dialog
                v-model="dialog"
                max-width="290"
        >
            <v-card>
                <v-card-title class="headline">
                    Confimation
                </v-card-title>

                <v-card-text>
                    Etes-vous sûr de vouloir supprimer cette clé de traduction ?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="lightgrey"
                            @click="dialog = false"
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                            color="red"
                            dark
                            @click="deleteKey"
                    >
                        Confirmer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

    import axiosHttp from '@/http-common'

    export default {
        name: 'Traductions',
        data: () => ({
            search: '',
            keysList : [],
            headers: [
                { text: 'Clé de traduction', value: 'key'},
                { text: 'Français', value: 'fr' },
                { text: 'Néerlandais', value: 'nl' },
                { text: 'Italien', value: 'it' },
                { text: 'Anglais', value: 'en' },
            ],
            dialog: false,
            currentKey : ""
        }),
        mounted () {
            axiosHttp.http
                .get("/ns/i18n/getAll")
                .then(response => {
                    this.keysList = this.keysList.concat(response.data.data);
                    axiosHttp.httpPreprod
                        .get("/ns/i18n/getAll")
                        .then(response => {
                            response.data.data.map((a) => {
                                if (!this.keysList.find(k => k.key === a.key)) {
                                    this.keysList.push(a);
                                }
                            });
                        })
                        .catch(error => console.log(error));
                })
                .catch(error => console.log(error));
        },
        methods: {
            updateKey(event, key, lang){

                let data = [{
                    "key": key,
                    "lang": lang,
                    "value": event.target.value
                }];

                axiosHttp.http
                    .post("/ns/i18n/setTrad", data)
                    .catch(error => console.log(error));
                axiosHttp.httpPreprod
                    .post("/ns/i18n/setTrad", data)
                    .catch(error => console.log(error));
            },
            deleteKey(){
                let keyToDelete = this.keysList.findIndex((e) => e.key == this.currentKey);

                axiosHttp.http
                    .post("/ns/i18n/delete", {key: this.currentKey})
                    .then( response =>
                        {
                            this.keysList.splice(keyToDelete, 1)
                            this.dialog = false
                        }
                    )
                    .catch(error => console.log(error))
                axiosHttp.httpPreprod
                    .post("/ns/i18n/delete", {key: this.currentKey})
                    .then( response =>
                        {
                            this.keysList.splice(keyToDelete, 1)
                            this.dialog = false
                        }
                    )
                    .catch(error => console.log(error))
            }
        }
    }
</script>

<style scoped>
    .search{
        max-width:500px;
    }
</style>
