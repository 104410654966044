var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"traductions"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.keysList,"item-key":"key","search":_vm.search,"items-per-page":15},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-5 pt-5 mb-8 search",attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();_vm.currentKey = item.key, _vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-delete-outline ")])],1),_vm._v(" "+_vm._s(item.key)+" ")]}},{key:"item.fr",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":""},on:{"blur":function($event){return _vm.updateKey($event,item.key,'fr')}},model:{value:(item.fr),callback:function ($$v) {_vm.$set(item, "fr", $$v)},expression:"item.fr"}})]}},{key:"item.nl",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":""},on:{"blur":function($event){return _vm.updateKey($event,item.key,'nl')}},model:{value:(item.nl),callback:function ($$v) {_vm.$set(item, "nl", $$v)},expression:"item.nl"}})]}},{key:"item.it",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":""},on:{"blur":function($event){return _vm.updateKey($event,item.key,'it')}},model:{value:(item.it),callback:function ($$v) {_vm.$set(item, "it", $$v)},expression:"item.it"}})]}},{key:"item.en",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":""},on:{"blur":function($event){return _vm.updateKey($event,item.key,'en')}},model:{value:(item.en),callback:function ($$v) {_vm.$set(item, "en", $$v)},expression:"item.en"}})]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Confimation ")]),_c('v-card-text',[_vm._v(" Etes-vous sûr de vouloir supprimer cette clé de traduction ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"lightgrey"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":_vm.deleteKey}},[_vm._v(" Confirmer ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }